<template lang="pug">
.main-wrapper.caixas-salvar
    ProgressBar(v-if='waiting' mode="indeterminate")
    div(v-else)
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "debito-credito-negociado" }'> Débito e Crédito Negociado</router-link> /
                        <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

        form(@submit.prevent='handleSubmit()')
            ProgressBar(v-if='waitingForm' mode="indeterminate")
            div(v-else)
                Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } débito crédito negociado`")
                    .p-grid.p-fluid.p-align-start(style='margin: 0 auto;')
                        .p-col-12.p-md-3
                            label.form-label Tipo:
                            SelectButton(v-model='$v.model.ie_tipo.$model' :options='options.tipos' optionValue='value' dataKey='value' optionLabel='text')
                            .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Tipo</b> é obrigatório.

                        .p-col-12.p-md-9(:class="{ 'form-group--error': submitted && $v.model.cd_empresa_associado.$error }")
                            div(v-if='model.nm_pessoa_fisica')
                                label.form-label Correntista:
                                .p-inputgroup
                                    InputText(type='text' v-model='model.nm_pessoa_fisica')
                                    Button.p-button-danger(icon='jam jam-rubber' @click="model.nm_pessoa_fisica = ''")
                            BuscarPaciente(v-else ref='buscarPaciente' label='Correntista' :cancelarPopup='true' :medEmpresa="true" :hideCadastro='true' @empresaInativa='dialogEmpresaSuspensa = !!$event, motivoSuspensaoEmpresa = $event')
                            .feedback--errors(v-if='submitted && $v.model.cd_empresa_associado.$error')
                                .form-message--error(v-if="!$v.model.cd_empresa_associado.required") <b>Correntista</b> é obrigatório.
                        
                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                            label.form-label Valor:
                            .p-inputgroup
                                span.p-inputgroup-addon {{'R$'}}
                                currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                                    v-model='$v.model.nr_valor.$model' locale='pt-BR' :auto-decimal-mode='true'
                                    :value-range='{ min: 0 }')
                            .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                                .form-message--error(v-if="!$v.model.nr_valor.required") <b>Valor</b> é obrigatório.

                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.nr_total_parcelas.$error }")
                            label.form-label Parcelas:
                            InputText(v-model='$v.model.nr_total_parcelas.$model' type='number')
                            .feedback--errors(v-if='submitted && $v.model.nr_total_parcelas.$error')
                                .form-message--error(v-if="!$v.model.nr_total_parcelas.required") <b>Parcelas</b> é obrigatório.

                        .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.dt_inicio_cobranca_i.$error }")
                            label.form-label Data de início da cobrança:
                            Calendar( v-model='$v.model.dt_inicio_cobranca_i.$model' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :touchUI='windowInnerWidth < 576' )
                            .feedback--errors(v-if='submitted && $v.model.dt_inicio_cobranca_i.$error')
                                .form-message--error(v-if="!$v.model.dt_inicio_cobranca_i.required") <b>Data de início da cobrança</b> é obrigatório.

                        .p-col-12(style="align-self: flex-end;")
                            .p-inputgroup
                                .p-input-icon-left.p-input-icon-right
                                    InputText(
                                        type="text"
                                        placeholder="Anexar documento"
                                        v-model="model.nm_anexo"
                                        @click="$refs.refComprovante.click()"
                                        readonly)
                                    i.jam.jam-attachment(@click="$refs.refComprovante.click()" v-tooltip.top="'Anexar'")
                                Button(
                                    :disabled="!model.aq_anexo"
                                    type='button'
                                    icon="pi pi-external-link"
                                    v-tooltip.top="'Vizualizar anexo'"
                                    @click="abrirAnexo()")

                            input(
                                v-show='false'
                                type="file"
                                ref="refComprovante"
                                @change="nomeAnexo()"
                                accept=".")

                        .p-col-12
                            label.form-label Observação:
                            Textarea(type='text' rows="3" v-model='model.ds_observacao')

                        .p-col-12
                            fieldset(v-if='model.cd_dc_negociado_parcelas.length')
                                legend.text-title Parcelas
                                DataTable.datatable-list(:value='model.cd_dc_negociado_parcelas')
                                    Column(headerStyle='width: 25%;' bodyStyle='text-align: center' field='nr_parcela' header='Nº parcela')
                                    Column(headerStyle='width: 25%;' bodyStyle='text-align: center' field='dt_lancamento' header='Data de lançamento')
                                    Column(headerStyle='width: 25%;' bodyStyle='padding: 0; text-align: center;' field='ie_situacao' header='Situação')
                                        template(#body='props')
                                            .cell(:style="{'background-color': options.situacao.find(item => item.value === props.data.ie_situacao)?.color}")
                                                span {{ options.situacao.find(item => (item.value === props.data.ie_situacao) && (props.data.ie_situacao !== null))?.text }} <br />
                                    Column(headerStyle='width: 25%;' bodyStyle='text-align: right' field='nr_valor_parcela' header='Valor' sortable)
                                        template(#body='props')
                                            span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor_parcela) }}
                                    ColumnGroup(type="footer")
                                        Row
                                            Column(footer="Total:" :colspan="3" :footerStyle="{'text-align':'right'}")
                                            Column(:footer="formatPrice(model.nr_valor)" :footerStyle="{'text-align':'right'}")

                        .p-col-12.mt-2(v-if="model.id")
                            .p-grid
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                .p-col-12.p-md-6.aux-2
                                    span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                        .p-col-12.mt-2
                            Button(label='Salvar' type="submit")
</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
    .p-input-icon-left,
    .p-input-icon-right {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .p-input-icon-left > i,
    .p-input-icon-right > i {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }
    .p-input-icon-left > i:first-of-type {
        left: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-right > i:last-of-type {
        right: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2rem;
    }
    .p-input-icon-right > .p-inputtext {
        padding-right: 2rem;
    }
    .text-title {
        color: #657786;
        font-size: 14px;
        font-weight: 700;
    }
    .datatable-list {
        .p-column-title, td {
            font-size: 12px;
        }
        .cell {
            padding: 16px 0;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import InputNumber from 'primevue/inputnumber'
    import InputSwitch from 'primevue/inputswitch'
    import Textarea from 'primevue/textarea'
    import BuscarPaciente from './../Paciente/BuscarPaciente'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
    import ColumnGroup from 'primevue/columngroup'
    import Row from 'primevue/row'
    
    import { DebitoCreditoNegociado, DominioValor } from './../../middleware'
    import { required } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, InputNumber, Textarea, Calendar, DataTable, Column, ColumnGroup,
                InputMask, SelectButton, Dropdown, Tooltip, MultiSelect, InputSwitch, BuscarPaciente, Row,
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true

            this.getDominios()

            let id = null
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id) {
                DebitoCreditoNegociado.find(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        
                        keys.forEach(key => this.model[key] = response.data[key])

                        if(this.model.aq_anexo) {
                            const aux1 = this.model.aq_anexo.lastIndexOf('/');
                            const aux2 = this.model.aq_anexo.lastIndexOf('?');
                            this.model.nm_anexo = this.model.aq_anexo.substring(aux1 + 1, aux2);
                        }
                        
                        this.model.id = id
                        this.model.nr_valor = parseInt(this.model.nr_valor, 10)
                        this.model.dt_inicio_cobranca_i = moment(response.data.dt_inicio_cobranca).format('DD/MM/YYYY')
                        this.model.dt_criado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        this.model.nm_usuario_cri = response.data.nm_usuario_cri
                        if (response.data.dt_atualizado) {
                            this.model.nm_usuario_edi = response.data.nm_usuario_edi
                            this.model.dt_atualizado_f = moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm')
                        }
                    }
                    this.waiting = false
                })
            } else this.waiting = false     
        },
        data () {
            return {
                model: {
                    nm_pessoa_fisica: '',
                    ie_tipo: null,
                    nr_valor: null,
                    dt_inicio_cobranca_i: null,
                    dt_inicio_cobranca: null,
                    nr_total_parcelas: null,
                    cd_empresa_associado: null,
                    aq_anexo: null,
                    nm_anexo: null,
                    ds_observacao: '',
                    cd_dc_negociado_parcelas: [],
                },
                options: {
                    tipos: [],
                    situacao: [],
                },
                waiting: true,
                waitingForm: false,
                submitted: false,
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
            }
        },
        validations () {
            let v = {
                model: {
                    ie_tipo: { required },
                    nr_valor: { required },
                    dt_inicio_cobranca_i: { required },
                    nr_total_parcelas: { required },
                    cd_empresa_associado: { required },
                }
            }
            return v
        },
        methods: {
            getDominios () {
                DominioValor.findAll({ds_mnemonico: ['SITUACAO_DC_NEGOCIADO_PARCELAS', 'TIPO_OPERACAO']}).then((response) => {
                    if(response.status === 200) {
                        this.options.situacao.push({ text: "- Selecione -", value: null })
                        response.data['SITUACAO_DC_NEGOCIADO_PARCELAS'].valores.forEach(item => {
                            this.options.situacao.push({
                                text: item.ds_valor,
                                value: item.ie_valor,
                                color: item.ds_cor,
                            })
                        })
                        response.data['TIPO_OPERACAO'].valores.forEach(item => {
                            if(item.ie_valor !== 'DEV') {
                                this.options.tipos.push({
                                    text: item.ds_valor,
                                    value: item.ie_valor,
                                })
                            }
                        })
                    }
                })
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            nomeAnexo() {
                const files = this.$refs.refComprovante.files;
                const comprovante = files.length && files[0];

                this.model.aq_anexo = comprovante;
                this.model.nm_anexo = comprovante.name
            },
            abrirAnexo() {
                if (this.model.aq_anexo)
                    window.open(this.model.aq_anexo);
                else this.$toast.error("Link para o arquivo não encontrado.");
            },
            handleSubmit () {
                this.submitted = true

                if (this.$refs.buscarPaciente?.model) this.model.cd_empresa_associado = this.$refs.buscarPaciente.model.empresa_associada_f.id
                this.model.dt_inicio_cobranca = moment(this.model.dt_inicio_cobranca_i, 'DD/MM/YYYY').utcOffset('-03:00').format('YYYY-MM-DD')

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                if (dataSend['aq_anexo'].startsWith('http')) delete dataSend['aq_anexo']

                this.waitingForm = true
                DebitoCreditoNegociado.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        this.$router.push(`/debito-credito-negociado/`)
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>